import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import {
  DependencyList,
  MutableRefObject,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

export type HorizontalScrollState = 'begin' | 'between' | 'end' | 'na';

export type SwipePageParam = {
  containerRef: MutableRefObject<HTMLElement | null>;
  scrollLeftRef: MutableRefObject<number>;
  width: number;
};

export const nextPage = ({
  containerRef,
  scrollLeftRef,
  width,
}: SwipePageParam) => {
  if (!containerRef.current) {
    return;
  }

  containerRef.current.scrollLeft =
    width *
    Math.floor(
      (scrollLeftRef.current + containerRef.current.offsetWidth) / width
    );
};

export const prevPage = ({
  containerRef,
  scrollLeftRef,
  width,
}: SwipePageParam) => {
  if (!containerRef.current) {
    return;
  }

  containerRef.current.scrollLeft =
    width *
    Math.ceil(
      (scrollLeftRef.current - containerRef.current.offsetWidth) / width
    );
};

const useHorizontalPager = (deps: DependencyList = []) => {
  const [scrollState, setScrollState] =
    useState<HorizontalScrollState>('begin');
  const containerRef = useRef<HTMLUListElement>(null);
  const firstChildRef = useRef<HTMLLIElement>(null);
  const scrollRef = useRef(0);

  useScrollPosition(
    ({ currPos }) => {
      if (!containerRef.current) {
        return;
      }

      scrollRef.current = currPos.x;

      if (
        containerRef.current.scrollWidth - containerRef.current.offsetWidth <=
        0
      ) {
        setScrollState('na');

        return;
      }

      if (scrollRef.current <= 0) {
        setScrollState('begin');
      } else if (
        Math.ceil(scrollRef.current) >=
        containerRef.current.scrollWidth - containerRef.current.offsetWidth
      ) {
        setScrollState('end');
      } else {
        setScrollState('between');
      }
    },
    [deps],
    firstChildRef as MutableRefObject<HTMLLIElement | undefined>,
    false, // useWindow
    25,
    containerRef as MutableRefObject<HTMLUListElement | undefined>
  );

  useLayoutEffect(() => {
    if (
      containerRef.current &&
      containerRef.current.scrollWidth - containerRef.current.offsetWidth <= 0
    ) {
      setScrollState('na');

      return;
    }
  }, deps);

  return { scrollState, containerRef, firstChildRef, scrollRef };
};

export default useHorizontalPager;
