import cx from 'classnames';
import styles from './Arrow.module.scss';

const ArrowNext = ({
  className,
  active = true,
}: {
  className?: string;
  active?: boolean;
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(styles.arrowIcon, !active && styles.disabled, className)}
    >
      <path
        d="M17.5 13L24.5 20L17.5 27"
        stroke={active ? '#4456DD' : '#F1F3FF'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#F1F3FF" />
    </svg>
  );
};

export default ArrowNext;
