import cx from 'classnames';
import styles from './Arrow.module.scss';
import ArrowNext from './ArrowNext';

const ArrowPrev = ({
  className,
  active = true,
}: {
  className?: string;
  active?: boolean;
}) => {
  return (
    <ArrowNext active={active} className={cx(styles.prevIcon, className)} />
  );
};

export default ArrowPrev;
