import Balancer from '@othree/react-wrap-balancer';
import { navigate, useLocation } from '@reach/router';
import axios from 'axios';
import cx from 'classnames';
import { isAfter, sub } from 'date-fns';
import Fuse from 'fuse.js';
import { graphql } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import omit from 'lodash.omit';
import shuffle from 'lodash.shuffle';
import sortBy from 'lodash.sortby';
import take from 'lodash.take';
import takeWhile from 'lodash.takewhile';
import throttle from 'lodash.throttle';
import queryString, { stringifyUrl } from 'query-string';

import {
  FormEvent,
  ForwardedRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import ArrowNext from '../components/InteractiveIcons/ArrowNext';
import ArrowPrev from '../components/InteractiveIcons/ArrowPrev';
import Layout from '../components/Layout/Layout';
import {
  Brand,
  Campaign,
  FormattedMerchant,
  Merchant,
  MerchantCategory,
  StripType,
} from '../types';
import { filterMissingRequiredFields, formatCurrency } from '../utils';
import useHorizontalPager, {
  HorizontalScrollState,
  SwipePageParam,
  nextPage,
  prevPage,
} from '../utils/use-horizontal-swiper';
import useIsSSR from '../utils/use-is-ssr';
import styles from './smartpay-shops.module.scss';

interface Props {
  data: {
    contentful: {
      merchantCollection: { items: Merchant[] };
      merchantCategoryCollection: { items: MerchantCategory[] };
      stripCollection: { items: StripType[] };
    };
  };
}

type ListsExpansationState = {
  [key: string]: boolean;
};

type MerchantBlockSize = 'regular' | 'large' | 'responsive';

const ALL = 'all';
const LATEST_MAX_COUNT = 18;
const COLLAPSE_MAX_COUNT = 30;

const HEADER_HEIGHT = 72; // desktop: 64
const SCROLL_MARGIN = 16;

export const shopsPageQuery = graphql`
  query ShopsPageQuery($language: String!, $dev: Boolean!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentful {
      stripCollection(
        order: sys_publishedAt_DESC
        limit: 10
        where: { domains_contains_some: "smartpay.co", active: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          title(locale: $language)
          link(locale: $language)
          type
          active(locale: $language)
          domains
          paths
          startsFrom
          endsOn
        }
      }
      merchantCategoryCollection(preview: $dev) {
        items {
          sys {
            id
          }
          contentfulMetadata {
            tags {
              id
              name
            }
          }
          title(locale: $language)
          titleKana
          slug
          description(locale: $language)
          icon {
            url
            width
            height
          }
          subMerchantCategoriesCollection {
            items {
              sys {
                id
              }
              slug
            }
          }
          isRoot
          order
        }
      }
      merchantCollection(
        order: sys_firstPublishedAt_DESC
        limit: 300
        where: { physicalStore_not: true }
        preview: $dev
      ) {
        items {
          sys {
            id
            firstPublishedAt
          }
          contentfulMetadata {
            tags {
              id
              name
            }
          }
          merchantId
          title(locale: $language)
          titleKana
          description(locale: $language)
          keywords
          logo {
            url
            width
            height
          }
          slug
          url
          highlight
          coverMobile {
            url
            width
            height
          }
          coverDesktop {
            url
            width
            height
          }
          categoryCollection(limit: 5) {
            items {
              slug
              title
            }
          }
          merchantBrandCollection(limit: 5) {
            items {
              sys {
                id
              }
              slug
              url
              title(locale: $language)
              titleKana
              description(locale: $language)
              coverMobile {
                height
                width
                url
              }
              coverDesktop {
                height
                width
                url
              }
              contentfulMetadata {
                tags {
                  name
                  id
                }
              }
              logo {
                url
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;

const BLOCK_SIZE_REGULAR = 'regular';
const BLOCK_SIZE_LARGE = 'large';
const BLOCK_SIZE_ERSPONSIZE = 'responsive';
const DEFAULT_BLOCK_SIZE = BLOCK_SIZE_REGULAR;
const BLOCK_WIDTH = {
  [BLOCK_SIZE_REGULAR]: 175,
  [BLOCK_SIZE_LARGE]: 210,
  [BLOCK_SIZE_ERSPONSIZE]: 175,
};
const BLOCK_LOGO_WIDTH = {
  [BLOCK_SIZE_REGULAR]: 64,
  [BLOCK_SIZE_LARGE]: 80,
  [BLOCK_SIZE_ERSPONSIZE]: 64,
};

const PagerController = ({
  scrollState,
  containerRef,
  scrollLeftRef,
  width,
}: {
  scrollState: HorizontalScrollState;
} & SwipePageParam) => {
  return (
    <div
      className={cx(styles.controllers, scrollState === 'na' && styles.hidden)}
    >
      <button
        aria-label="Prev"
        type="button"
        className={styles.buttonPrev}
        onClick={() => {
          prevPage({
            containerRef,
            scrollLeftRef,
            width,
          } as SwipePageParam);
        }}
      >
        <ArrowPrev active={scrollState !== 'begin'} />
      </button>
      <button
        aria-label="Next"
        type="button"
        className={styles.buttonNext}
        onClick={() => {
          nextPage({
            containerRef,
            scrollLeftRef,
            width,
          });
        }}
      >
        <ArrowNext active={scrollState !== 'end'} />
      </button>
    </div>
  );
};

const MerchantBlock = ({
  data: merchant,
  className,
  size,
  inputRef,
}: {
  data?: FormattedMerchant;
  className?: string;
  size?: MerchantBlockSize;
  inputRef?: ForwardedRef<HTMLLIElement>;
}) => {
  const campaign = merchant?.campaign;
  const safeSize = size || DEFAULT_BLOCK_SIZE;
  const blockWidth = BLOCK_WIDTH[safeSize];
  const logoWidth = BLOCK_LOGO_WIDTH[safeSize];

  if (!merchant) {
    return null;
  }

  return (
    <li ref={inputRef} className={cx(styles[safeSize], className)}>
      {campaign && (
        <span className={styles.campaign}>{`${
          campaign.discountType === 'amount'
            ? formatCurrency(campaign.discountAmount || 0)
            : `${campaign.discountPercentage || 0}%`
        } OFF`}</span>
      )}
      <a href={merchant.url} target="_blank" rel="noopener noreferrer">
        <figure>
          <img
            src={stringifyUrl({
              url: merchant.coverMobile?.url || '',
              query: { w: 420, fm: 'jpg' },
            })}
            alt=""
            width={blockWidth}
            height={blockWidth}
            className={styles.cover}
          />
          <img
            src={stringifyUrl({
              url: merchant.logo?.url || '',
              query: { w: 160 },
            })}
            alt=""
            width={logoWidth}
            height={logoWidth}
            className={styles.logo}
          />
          <figcaption>
            <Balancer>{merchant.title}</Balancer>
          </figcaption>
        </figure>
      </a>
    </li>
  );
};

const updateSearchQuery = throttle(
  (keyword) => {
    navigate(
      `${location.pathname}${
        keyword
          ? '?' +
            queryString.stringify({
              q: keyword,
            })
          : ''
      }`
    );
  },
  300,
  { leading: true, trailing: true }
);

const ShopsPage = ({ data }: Props) => {
  const isSSR = useIsSSR();
  const { hash, search } = useLocation();
  const { t, originalPath, i18n } = useI18next();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  const {
    contentful: {
      merchantCollection: { items: merchants },
      merchantCategoryCollection: { items: merchantCategories },
      stripCollection: { items: strips },
    },
  } = data;

  const rootCategories = sortBy(
    merchantCategories.filter((category) => category.isRoot),
    'order'
  );
  const defaultListsExpansationState: ListsExpansationState =
    rootCategories.reduce<ListsExpansationState>(
      (state, category) => ({ ...state, [category.slug]: false }),
      {
        [ALL]: false,
      }
    );
  const [isListsExpanded, setIsListsExpanded] = useState<ListsExpansationState>(
    defaultListsExpansationState
  );
  const requestCategory = rootCategories.find(
    (category) => category.slug === hash.slice(1)
  )?.slug;

  const [currentCategory, setCurrentCategory] = useState(
    requestCategory || ALL
  );
  const currentCategoryTitle = rootCategories.find(
    (category) => category.slug === currentCategory
  )?.title;
  const isListExpanded = isListsExpanded[currentCategory];
  const validMerchants: FormattedMerchant[] = useMemo(() => {
    return filterMissingRequiredFields<Merchant>(merchants, [
      'slug',
      'url',
      'coverMobile',
      'logo',
    ])
      .map((node) => {
        return {
          id: node.merchantId,
          sys_id: node.sys.id,
          firstPublishedAt: node.sys.firstPublishedAt,
          ...omit(node, ['sys', 'contentfulMetadata', 'categoryCollection']),
          categories: node.categoryCollection?.items.map((item) => item.slug),
          categoryTitles: node.categoryCollection?.items.map(
            (item) => item.title
          ),
          tags: node.contentfulMetadata?.tags.map((t) => t.name),
          type: 'merchant',
          campaign: campaigns.find((c) => c.merchant === node.merchantId),
        } as any;
      })
      .reduce((list, node) => {
        const brands = [
          omit(node, ['merchantBrandCollection']) as FormattedMerchant,
        ];

        filterMissingRequiredFields<Brand>(
          node.merchantBrandCollection?.items || [],
          ['slug', 'url']
        ).forEach((brand, index) => {
          const id = `${node.merchantId}-${index + 1}`;

          brands.push({
            id, // Start from 1
            sys_id: brand.sys.id,
            firstPublishedAt: brand.sys.firstPublishedAt,
            merchantId: id,
            ...omit(brand, ['sys', 'contentfulMetadata']),
            categories: node.categories,
            categoryTitles: node.categoryTitles,
            tags: brand.contentfulMetadata?.tags,
            type: 'brand',
          } as FormattedMerchant);
        });

        return [...list, ...brands];
      }, []);
  }, [merchants, campaigns]);

  // Randomly sort highlight merchants
  const highlightMerchants = useMemo(
    () =>
      shuffle(
        validMerchants.filter(
          (merchant: FormattedMerchant) => merchant.highlight
        )
      ),
    [validMerchants, isSSR] // isSSR is used to force update the list on every view
  );
  /**
   * Take shops established within one month, at least take 18 shop
   * Then randomly sort/pick 18 of them
   */
  const newMerchants = useMemo(
    () =>
      take(
        shuffle(
          takeWhile(validMerchants, (merchant, index) => {
            return (
              index < LATEST_MAX_COUNT ||
              isAfter(
                new Date(merchant.firstPublishedAt as string),
                sub(new Date(), { months: 1 })
              )
            );
          })
        ),
        LATEST_MAX_COUNT
      ),
    [validMerchants, isSSR] // isSSR is used to force update the list on every view
  );

  const campaignMerchants = useMemo(
    () =>
      validMerchants.filter((merchant: FormattedMerchant) => {
        return !!merchant.campaign;
      }),
    [validMerchants, isSSR]
  );

  const availableMerchants = useMemo(
    () =>
      sortBy(
        validMerchants.filter((merchant: FormattedMerchant) => {
          return (
            currentCategory === ALL ||
            merchant.categories.findIndex(
              (category) => category === currentCategory
            ) >= 0
          );
        }),
        'title'
      ),
    [validMerchants, currentCategory, isSSR]
  );

  // Horizontal Pager
  const {
    scrollState: popularScrollState,
    containerRef: popularRef,
    firstChildRef: popularItem1Ref,
    scrollRef: popularScrollRef,
  } = useHorizontalPager();
  const {
    scrollState: latestScrollState,
    containerRef: latestRef,
    firstChildRef: latestItem1Ref,
    scrollRef: latestScrollRef,
  } = useHorizontalPager();
  const {
    scrollState: campaignScrollState,
    containerRef: campaignRef,
    firstChildRef: campaignItem1Ref,
    scrollRef: campaignScrollRef,
  } = useHorizontalPager([campaignMerchants]);

  const { q } = queryString.parse(search);
  const query = ((Array.isArray(q) ? q[0] : q) || '').trim();
  const [keyword, setKeyword] = useState(query);

  const onSearch = useCallback(
    (event?: FormEvent<HTMLFormElement>) => {
      event?.preventDefault();
      updateSearchQuery(keyword);
    },
    [keyword]
  );

  const fuse = useMemo(() => {
    return new Fuse<FormattedMerchant>(validMerchants, {
      keys: [
        {
          name: 'title',
          weight: 2,
        },
        {
          name: 'titleKana',
          weight: 2,
        },
        {
          name: 'slug',
          weight: 2,
        },
        'description',
        'categories',
        'categoryTitles',
        'tags',
        'keywords',
      ],
      threshold: 0.4,
    });
  }, [validMerchants]);

  const searchResult = useMemo(
    () => (query ? fuse.search(query).map((r) => r.item) : []),
    [query]
  );
  const availableSearchResult = useMemo(
    () =>
      searchResult.filter(
        (merchant: FormattedMerchant) =>
          currentCategory === ALL ||
          merchant.categories.indexOf(currentCategory) >= 0
      ),
    [searchResult, currentCategory]
  );

  useEffect(() => {
    const targetCategory =
      rootCategories.find(
        (category) => category.slug === (hash ? hash.slice(1) : '')
      )?.slug || ALL;

    setCurrentCategory(targetCategory);
  }, [hash]);

  useEffect(() => {
    axios
      .post(`${process.env.GATSBY_API_BASE_URL}/consumers/auth/refresh`, null, {
        withCredentials: true,
      })
      .then((response) =>
        axios.get(`${process.env.GATSBY_API_BASE_URL}/campaigns/me`, {
          headers: {
            Authorization: `Bearer ${response.data.accessToken}`,
          },
        })
      )
      .catch(() => axios.get(`${process.env.GATSBY_API_BASE_URL}/campaigns`))
      .then((response) => {
        setCampaigns(response.data);
      });
  }, []);

  // Scroll to search form or category nav based on URL
  useLayoutEffect(() => {
    if (hash === `#${currentCategory}` || query) {
      const targetTop = document.getElementById('search-form')?.offsetTop;
      const windowScrollY = window.scrollY;
      const windowHeight = window.innerHeight;

      if (
        targetTop !== undefined &&
        targetTop > windowScrollY + windowHeight * 0.3
      ) {
        setTimeout(() => {
          window.scrollTo(0, (targetTop || 0) - HEADER_HEIGHT - SCROLL_MARGIN);
        }, 5);
      }
    }
  }, [hash, query, currentCategory]);

  return (
    <Layout t={t} originalPath={originalPath} i18n={i18n} strips={strips}>
      <section className={styles.hero}>
        <div className={styles.wrapper}>
          <h1>
            <Trans i18nKey="shops-page-title" />
          </h1>
          <h2>
            <Trans i18nKey="shops-page-subtitle" />
          </h2>
        </div>
      </section>
      <section className={styles.container}>
        <div className={styles.content}>
          <div id="highlight-shops" className={styles.box}>
            <div>
              <div className={styles.sectionHeader}>
                <h3>{t('highlight-shops')}</h3>
                <PagerController
                  scrollState={popularScrollState}
                  containerRef={popularRef}
                  scrollLeftRef={popularScrollRef}
                  width={210 + 20}
                />
              </div>
              <ul
                ref={popularRef}
                className={cx(styles.list, styles.highlight, styles.horizontal)}
              >
                {highlightMerchants.map((merchant, index) => (
                  <MerchantBlock
                    inputRef={index === 0 ? popularItem1Ref : null}
                    key={merchant.slug + isSSR}
                    data={merchant}
                    size={BLOCK_SIZE_LARGE}
                  />
                ))}
              </ul>
            </div>
          </div>
          <div id="new-shops" className={styles.box}>
            <div>
              <div className={styles.sectionHeader}>
                <h3>{t('new-shops')}</h3>
                <PagerController
                  scrollState={latestScrollState}
                  containerRef={latestRef}
                  scrollLeftRef={latestScrollRef}
                  width={175 + 16}
                />
              </div>
              <ul
                ref={latestRef}
                className={cx(styles.list, styles.horizontal)}
              >
                {newMerchants.map((merchant, index) => (
                  <MerchantBlock
                    inputRef={index === 0 ? latestItem1Ref : null}
                    key={merchant.slug + isSSR}
                    data={merchant}
                  />
                ))}
              </ul>
            </div>
          </div>

          {campaignMerchants.length > 0 && (
            <>
              <div
                id="campaign-shops"
                className={cx(styles.box, styles.campaigns)}
              >
                <div>
                  <div className={styles.sectionHeader}>
                    <h3>{t('ongoing-campaign')}</h3>
                    <PagerController
                      scrollState={campaignScrollState}
                      containerRef={campaignRef}
                      scrollLeftRef={campaignScrollRef}
                      width={175 + 16}
                    />
                  </div>
                  <ul
                    ref={campaignRef}
                    className={cx(styles.list, styles.horizontal)}
                  >
                    {campaignMerchants.map((merchant, index) => (
                      <MerchantBlock
                        inputRef={index === 0 ? campaignItem1Ref : null}
                        key={merchant.slug + isSSR}
                        data={merchant}
                      />
                    ))}
                  </ul>
                </div>
                <p className={styles.disclaimer}>
                  <Trans
                    i18nKey="campaign-disclaimer"
                    components={{ b: <b /> }}
                  />
                </p>
              </div>
            </>
          )}

          <div id="main-content" className={cx(styles.box, styles.main)}>
            <form
              onSubmit={onSearch}
              id="search-form"
              className={styles.searchForm}
            >
              <input
                type="text"
                id="search"
                value={keyword}
                placeholder="例）ファッション"
                onChange={(event) => {
                  setKeyword(event.target.value);
                  updateSearchQuery(event.target.value);
                }}
              />
            </form>
            <div id="category-nav" className={styles.categories}>
              <a
                href={`#all`}
                className={cx(currentCategory === 'all' && styles.active)}
              >
                {t('all-shops')}
              </a>
              {rootCategories.map((category) => {
                return (
                  <a
                    href={`#${category.slug}`}
                    className={cx(
                      currentCategory === category.slug && styles.active
                    )}
                    key={category.slug}
                  >
                    {category.title}
                  </a>
                );
              })}
            </div>
            <div>
              {query ? (
                <>
                  <h3>{t('shops-page-title')}</h3>
                  {availableSearchResult &&
                  availableSearchResult?.length > 0 ? (
                    <ul className={styles.list}>
                      {availableSearchResult.map((merchant) => (
                        <MerchantBlock
                          data={merchant}
                          key={merchant.slug}
                          size={BLOCK_SIZE_ERSPONSIZE}
                        />
                      ))}
                    </ul>
                  ) : (
                    <p className={styles.emptyResult}>
                      {t('not-found-any-shops')}
                    </p>
                  )}
                </>
              ) : (
                <>
                  <h3>
                    {currentCategory === 'all'
                      ? t('all-shops')
                      : currentCategoryTitle}
                  </h3>
                  <ul className={styles.list}>
                    {availableMerchants.map((merchant, index) => (
                      <MerchantBlock
                        data={merchant}
                        key={merchant.slug}
                        className={cx(
                          index >= COLLAPSE_MAX_COUNT &&
                            !isListExpanded &&
                            styles.hidden
                        )}
                        size={BLOCK_SIZE_ERSPONSIZE}
                      />
                    ))}
                  </ul>
                  {!isListExpanded &&
                    availableMerchants.length > COLLAPSE_MAX_COUNT && (
                      <div className={styles.actions}>
                        <button
                          className={styles.action}
                          onClick={() => {
                            setIsListsExpanded({
                              ...isListsExpanded,
                              [currentCategory]: true,
                            });
                          }}
                        >
                          {t('show-more')}
                        </button>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ShopsPage;
